import React from 'react'

import HeaderMeta from 'components/HeaderMeta'

import Content from 'components/base/Content'
import Paragraph from 'components/base/Paragraph'
import Title from 'components/base/Title'
import SectionRule from 'components/base/SectionRule/styles'

import PageHero, { Theme as PageHeroTheme } from 'components/blocks/PageHero'

const Terms: React.FC = (): React.ReactElement => (
  <div>
    <HeaderMeta
      title='Terms of Use | Insurance Lounge'
      description='View our terms of use for details on how we handle copyrights, trademarks, accounts, cookies, and more on our website.'
    />
    <PageHero theme={PageHeroTheme.noimage}>Terms</PageHero>
    <Content short={true} margined={true}>
      <SectionRule />
      <Paragraph>
        Welcome to InsuranceLounge.com, (the &quot;Web Site&quot;), a web
        site where consumers and small businesses can research and shop for
        various insurance products. The Web Site is owned and operated by
        Insurance Lounge<sup>®</sup>, Inc. (hereinafter, the
        &quot;Company&quot; or &quot;Insurance Lounge<sup>®</sup>&quot;), a
        licensed insurance agency (see Licensing, below). The Company
        provides its services to you subject to the following conditions. If
        you visit or shop at this Web Site, you accept these conditions.
        Please read them carefully.
      </Paragraph>
      <Title level={4}>Legal Jurisdictions Only</Title>
      <Paragraph>
        The insurance services and products described on this Web Site are
        only for persons in those states and jurisdictions where such
        products and services may legally be sold. Nothing on this Web Site
        shall be considered a solicitation to buy or an offer to sell any
        products or services to any person in any jurisdiction where such
        offer, solicitation, purchase or sale would be unlawful.
      </Paragraph>
      <Title level={4}>Use Limitation</Title>
      <Paragraph>
        This Web Site is for the use of consumers and small businesses that
        are researching and shopping for insurance products. You are not
        permitted to use this Web Site or its contents for any commercial or
        non-personal purpose (direct or indirect). The Company reserves the
        right to prevent any party who engages in such non-permitted use(s)
        from accessing this Web Site.
      </Paragraph>
      <Title level={4}>Trademarks</Title>
      <Paragraph>
        Insurance Lounge<sup>®</sup>, Insurance Host, Insurance Hostess, and
        Insurance Concierge are registered trademarks of the Company. The
        Company’s trademarks may not be used in connection with any
        product or service that is not the Company’s, in any manner that
        is likely to cause confusion among customers or in any manner that
        disparages or discredits the Company. All trademarks not owned by the
        Company that appear on this Web Site are the property of their
        respective owners.
      </Paragraph>
      <Title level={4}>Copyrights</Title>
      <Paragraph>
        All content included on this site, such as text, graphics, logos,
        button icons, images, audio clips, digital downloads, data
        compilations, and software, is the property of the Company or its
        content suppliers and protected by United States and international
        copyright laws. The compilation of all content on this site is the
        exclusive property of the Company and protected by United States and
        international copyright laws. All software used on this site is the
        property of the Company or its software suppliers and protected by
        United States and international copyright laws.
      </Paragraph>
      <Title level={4}>Your Account</Title>
      <Paragraph>
        If you use this Web Site, you are responsible for maintaining the
        confidentiality of your account and password and for restricting
        access to your computer, and you agree to accept responsibility for
        all activities that occur under your account or password. Further, by
        using this Web Site, you warrant that you are at least 18 years of
        age and possess the legal authority to use this Web Site. The Company
        does not sell products to minors, but it sells them to adults. If you
        are purchasing a product on behalf of a minor, you agree to supervise
        all usage of this Web Site by minors under your name or account. The
        Company reserves the right to refuse service, terminate accounts or
        remove or edit content in its sole discretion.
      </Paragraph>
      <Title level={4}>Cookies</Title>
      <Paragraph>
        Cookies are small text files sent to a user’s browser for data
        storage. These cookies allow us to identify that the same party is
        moving within the Web Sites without having departed to another Web
        site. Additionally, cookies allow us to determine the Web site, if
        any, which referred you to our Web Site. The cookies also allow us to
        enhance your activity and ascertain your site preferences on our Web
        Sites. Without cookies, some multimedia images and graphics may not
        display properly and you may have more difficulty navigating through
        the Web Sites. By themselves, cookies do not tell us your e-mail
        address or other personally identifiable information. Only you can
        provide that information on our Web Sites, if you choose to do so. We
        do not use cookies to collect, track, or store personally
        identifiable information. Our cookies will never be used to track
        your activity on any third-party Web sites or to send spam. We use
        cookies so you won’t have to reenter information each time you
        visit this Site. If you do not want to receive cookies from this
        Site, you can set your browser to not accept cookies.
      </Paragraph>
      <Title level={4}>Disclaimer of Warranties and Limited Liability</Title>
      <Paragraph>
        THIS WEB SITE IS PROVIDED BY THE COMPANY ON AN &quot;AS IS&quot; AND
        &quot;AS AVAILABLE&quot; BASIS. THE COMPANY MAKES NO REPRESENTATIONS
        OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF
        THIS SITE OR THE INFORMATION, CONTENT, MATERIALS, OR PRODUCTS
        INCLUDED ON THIS SITE. YOU EXPRESSLY AGREE THAT YOUR USE OF THIS SITE
        IS AT YOUR SOLE RISK. ANY LINKS TO OTHER WEB SITES ARE PROVIDED AS A
        COURTESY. THEY ARE NOT INTENDED TO NOR DO THEY CONSTITUTE AN
        ENDORSEMENT BY THE COMPANY OF THE LINKED MATERIALS.TO THE FULL EXTENT
        PERMISSIBLE BY APPLICABLE LAW, THE COMPANY DISCLAIMS ALL WARRANTIES,
        EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES
        OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. THE COMPANY
        DOES NOT WARRANT THAT THIS SITE, ITS SERVERS, OR E-MAIL SENT FROM THE
        COMPANY ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THE COMPANY
        WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE
        OF THIS SITE, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT,
        INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES, EVEN IF THE COMPANY
        IS MADE AWARE OF THE POSSIBILITY OF SUCH DAMAGES.
      </Paragraph>
      <Paragraph>
        CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR
        THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY
        TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR
        LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL
        RIGHTS.
      </Paragraph>
      <Title level={4}>Applicable Law</Title>
      <Paragraph>
        By visiting this Web Site, you agree that the laws of the State of
        Oregon, without regard to principles of conflict of laws, will
        exclusively govern these Legal and Licensing terms and any dispute of
        any sort that may arise between you and the Company. The only
        exception to this is information relating to Medicare plans, which is
        governed by Federal law.
      </Paragraph>
      <Title level={4}>Existing Insurance Coverage</Title>
      <Paragraph>
        DO NOT CANCEL ANY EXISTING INSURANCE UNTIL YOU RECEIVE WRITTEN
        CONFIRMATION FROM THE INSURANCE COMPANY TO WHICH YOU ARE APPLYING
        THAT YOUR NEW POLICY IS IN EFFECT.
      </Paragraph>
      <Title level={4}>Licensing</Title>
      <Paragraph>
        This Web Site is brought to you by licensed brokers in each
        respective state which require insurance transactions to be conducted
        through a licensed agent/broker. Each agent/broker is associated with
        Insurance Lounge<sup>®</sup>, Inc., an Oregon corporation with
        principal places of business at:
      </Paragraph>
      <Paragraph>
        Insurance Lounge, Inc
        <br />
        1221 NE 7th St
        <br />
        Grants Pass, OR 97526
      </Paragraph>
      <Title level={4}>LICENSE NO.</Title>
      <Title level={4}>STATES</Title>
      <Paragraph>
        Oregon 16551113
        <br />
        Washington 805606
        <br />
        California
        <br />
        Nevada 813806
        <br />
        Arizona 1015680
      </Paragraph>
      <SectionRule />
    </Content>
  </div>
)

export default Terms
