import React from 'react'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import deployment from 'utils/deployment'

import { Props } from './types'

const HeaderMeta: React.FC<Props> = ({
  description = '',
  keywords = '',
  location: { pathname },
  noIndex = deployment !== 'production',
  ogDescription,
  ogImg = '/images/og-images/Default.webp',
  ogSiteName = 'Insurance Lounge',
  ogTitle,
  ogType = 'website',
  schema = undefined,
  title = 'Insurance Lounge',
}): React.ReactElement => {
  const locale = 'en_US'
  const url = `${process.env.PUBLIC_URL || ''}${pathname}`

  ogDescription =
    (ogDescription !== undefined && ogDescription) || description
  ogImg =
    (ogImg &&
      `${process.env.PUBLIC_URL || ''}/${ogImg.replace(/^\//, '')}`)
  ogTitle = (ogTitle !== undefined && ogTitle) || title

  let jsonSchema
  if (schema) {
    if (typeof schema !== 'object') {
      console.error(`Illegal schema value passed to HeaderMeta. Expected object, received ${typeof schema}.`) // eslint-disable-line no-console
    }
    else {
      jsonSchema = JSON.stringify(schema)
    }
  }

  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
      <link rel='canonical' href={url} />
      <meta property='og:locale' content={locale} />
      <meta property='og:type' content={ogType} />
      <meta property='og:title' content={ogTitle} />
      <meta property='og:description' content={ogDescription} />
      <meta property='og:url' content={url} />
      <meta property='og:site_name' content={ogSiteName} />
      {(noIndex && <meta name='robots' content='noindex' />) || undefined}
      {(ogImg && <meta property='og:image' content={ogImg} />) || undefined}
      {(jsonSchema && <script type='application/ld+json'>{jsonSchema}</script>) || undefined}
    </Helmet>
  )
}

export * from './types'

export default withRouter(HeaderMeta)
