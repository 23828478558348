import styled from 'styled-components'

import FONTS from 'constants/fonts'
import COLORS from 'constants/colors'
import MEDIA from 'constants/media'

import {
  Props, Theme,
} from './types'

/* eslint-disable import/prefer-default-export */
export const StyledList = styled.ul<Props>`
  font-size: ${(props: Props): string => {
    switch (props.theme) {
      case Theme.small:
        return `${FONTS.TEXTSMALL}`
      case Theme.medium:
        return `${FONTS.TEXTSMALL}`
      case Theme.large:
        return `${FONTS.TEXTMEDIUM}`
      default:
        return `${FONTS.TEXTMEDIUM}`
    }
  }};
  text-align: ${(props: Props): string => (props.centerItems ? 'center' : 'left')};
  font-weight: ${FONTS.WEIGHTLIGHT};
  list-style: ${(props: Props): string => (props.clean ? 'none' : 'disc')};
  margin-top: 0;
  margin-bottom: ${(props: Props): string => (props.hasMarginBottom !== false ? '20px' : '0')};
  margin-left: ${(props: Props): string => (props.center ? 'auto' : '0')};
  margin-right: ${(props: Props): string => (props.center ? 'auto' : '0')};
  display: ${(props: Props): string => (props.center ? 'inline-block' : 'block')};
  &:last-child {
    margin-bottom: 0;
  }

  ${MEDIA.medium`
    font-size: ${(props: Props): string => {
    switch (props.theme) {
      case Theme.small:
        return `${FONTS.TEXTSMALL}`
      case Theme.medium:
        return `${FONTS.TEXTMEDIUM}`
      case Theme.large:
        return `${FONTS.PARAGRAPHLARGE}`
      default:
        return `${FONTS.PARAGRAPH}`
    }
  }} !important;
  `}

  ${(props: Props): string | undefined => (props.theme === Theme.incidentList && `
    font-size: ${FONTS.PARAGRAPH};
    padding: 0 20px;
    list-style: none;
    max-width: 700px;
    margin: auto;
    li {
      margin-bottom: 5px;
      font-weight: ${FONTS.WEIGHTDEMI};
    }
    ${MEDIA.medium`
      font-size: ${FONTS.PARAGRAPHLARGE};
    `}
  `) || (props.theme === Theme.contentList && `
    li {
      margin-bottom: 10px;
    }
  `) || (props.theme === Theme.oneColumn && `
    max-width: 360px;
    margin: 0 auto 20px;
    li {
      margin-bottom: 10px;
    }
  `) || (props.theme === Theme.twoColumn && `
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto 20px;
    max-width: 800px;
    width: 100%;
    li {
      margin-bottom: 5px;
      width: 300px;
      margin-right: 20px;
    }
  `) || undefined}

  ${(props: Props): string | undefined => (props.blue && `
    color: ${COLORS.BLUE};
  `) || undefined}
`
