import {
  css,
  CSSObject,
  FlattenSimpleInterpolation,
} from 'styled-components'

export interface Media {
  xxlarge: Function,
  xlarge: Function,
  large: Function,
  medium: Function,
  small: Function,
  xsmall: Function,
  tiny: Function,
  progressbar: Function,
  ie11: Function,
}

export const sizes = {
  xxlarge: 2400,
  xlarge: 1400,
  large: 1280,
  medium: 1000,
  small: 800,
  xsmall: 640,
  tiny: 360,
  progressbar: 545,
}

const initialMediaFunc = (): undefined => undefined

const media: Media = Object.keys(sizes).reduce((acc, key) => {
  acc[key] = (first: TemplateStringsArray | CSSObject, ...args: TemplateStringsArray[]): FlattenSimpleInterpolation => css`
    @media (min-width: ${sizes[key]}px) {
      ${css(first, ...args)};
    }
  `

  return acc
}, {
  xxlarge: initialMediaFunc,
  xlarge: initialMediaFunc,
  large: initialMediaFunc,
  medium: initialMediaFunc,
  small: initialMediaFunc,
  xsmall: initialMediaFunc,
  tiny: initialMediaFunc,
  progressbar: initialMediaFunc,
  ie11: (first: TemplateStringsArray | CSSObject, ...args: TemplateStringsArray[]): FlattenSimpleInterpolation => css`
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      ${css(first, ...args)};
    }
  `,
})

export default media
