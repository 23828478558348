const LAYOUT = {
  WIDTH: '1280px',
  WIDTHVERYSHORT: '480px',
  WIDTHSHORT: '800px',
  WIDTHMEDIUM: '1160px',
  PADDING: '20px',
  VERTICALSPACE: '60px',
  VERTICALSPACEMOBILE: '40px',
}

export default LAYOUT
