import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

import Window from 'types/window.d'

import Script from 'components/base/Script'
import Content from 'components/base/Content'

import PageHero, { Theme as PageHeroTheme } from 'components/blocks/PageHero'

declare let window: Window

const AgencyQuoter: React.FC = (): React.ReactElement => {
  useEffect(() => {
    window.IXN_QUOTER_CONFIG = { id: '2b5fb754-5b2d-4d6b-a4a6-e30d903b7a65' }
  }, [])

  return (
    <div>
      <Script src='https://alq.ixn.tech/js/alq.widget.js' />
      <Helmet>
        <link href='https://alq.ixn.tech/css/reset.css' rel='stylesheet' />
      </Helmet>

      <PageHero theme={PageHeroTheme.noimage}>Agency Quoter</PageHero>

      <Content margined={true} short={true}>
        <div id='ixn-agency-quoter' />
      </Content>
    </div>
  )
}

export default AgencyQuoter
