import React from 'react'

import { StyledList } from './styles'

import { Props } from './types'

const List: React.FC<Props> = ({
  blue,
  theme,
  ...props
}): React.ReactElement => <StyledList blue={blue} theme={theme} {...props} />

export * from './types'

export default List
