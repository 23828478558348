import React, { useEffect } from 'react'

import { Props } from './types'

const Script: React.FC<Props> = ({ src }): null => {
  useEffect(() => {
    const existingScripts = Array.from(document.scripts).filter(script => script.src === src)

    existingScripts.map(existingScript => {
      document.head.removeChild(existingScript)
    })

    const script = document.createElement('script')
    script.src = src
    document.head.append(script)
  }, [ src ])

  return null
}

export default Script
