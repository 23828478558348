const COLORS = {
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  BLUE: '#143d8d',
  LIGHTBLUE: '#84afca',
  BGRBLUE: '#e1f3f3',
  TAN: '#f8f7e9',
  LIGHTYELLOW: '#fbfbe9',
  GOLD: '#faa61a',
  GREYLIGHT: '#f6f6f6',
  GREYMEDIUM: '#cccccc',
  GREYDARK: '#666666',
  BORDERS: '#cccccc',
  BORDERSSECTIONS: '#f0f0f0',
  BORDERSYELLOW: '#e5e5cc',
  ERROR: '#990000',
  WARNING: '#ffa500',
  PLACEHOLDER: '#999999',
}

export default COLORS
